<template>
  <div class="home">
    <Header />
    <main>
      <Services />
      <Contact />
    </main>
    <Footer />
    <BackToTop />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Services from "@/components/Services.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import BackToTop from "@/components/BackToTop.vue";

export default {
  name: "home",
  title:
    "Reformelec | Instal·lacions electriques, Reformes en general, Andorra",
  components: {
    Header,
    Services,
    Contact,
    Footer,
    BackToTop,
  },
};
</script>

<style scoped lang="scss">
@import "../assets/_custom.scss";
</style>