<template>
  <div id="contact">
    <mdb-container class="p-5">
      <mdb-row class="justify-content-center align-items-center mx-0 py-5">
        <!-- Content -->
        <mdb-col md="6" class="text-center text-md-left">
          <h2>
            <mdb-icon
              fas
              icon="angle-double-right"
              color="primary"
              class="mr-3"
            />{{ $t("contact.title") }}
          </h2>
          <p>
            <mdb-icon fas icon="mobile-alt" class="text-secondary mr-3" />(+
            376) 338 755
          </p>
          <p>
            <mdb-icon
              far
              icon="envelope-open"
              color="secondary"
              class="mr-3"
            />reformelec@andorra.ad
          </p>
          <p>
            <mdb-icon
              fas
              icon="map-marked-alt"
              color="secondary"
              class="mr-3"
            />Edifici Estornisecs 3, Anyos, AD400 La Massana
          </p>
          <p>
            <mdb-icon fas icon="clock" color="secondary" class="mr-3" />{{
              $t("contact.hours")
            }}
          </p>
        </mdb-col>

        <!-- /Content -->

        <!-- Map -->
        <mdb-col md="6" class="mb-5">
          <div
            id="map-container"
            class="rounded map-container mb-5"
            style="height: 400px"
          >
            <iframe
              width="100%"
              height="100%"
              style="border: 0"
              loading="lazy"
              allowfullscreen
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJSwUNKayKpRIRcHGZkXCUAAo&key=AIzaSyBOFI9lblXKOLIAyNUahf2p_PHqq5AkY50"
            ></iframe>
          </div>
        </mdb-col>
        <!-- /Map -->
      </mdb-row>
    </mdb-container>

    <!-- Separator -->
    <div class="custom-shape-divider-bottom-1617735635">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
    <!-- /Separator -->
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon } from "mdbvue";

export default {
  name: "Contact",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

#contact {
  position: relative;
}

.custom-shape-divider-bottom-1617735635 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1617735635 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 276px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1617735635 .shape-fill {
  fill: #5e003b;
}
</style>