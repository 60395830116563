import Vue from 'vue';
import Router from 'vue-router';
import i18n from "@/i18n"
import App from "@/App"
import {
  setDocumentLang,
  setDocumentTitle,
  setDocumentDescription
} from "@/util/i18n/document"

import Home from '@/views/Home';

Vue.use(Router);

const { locale } = i18n

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: locale,
  },
  {
    path: "/:locale",
    component: App,
    children: [
      {
        path: "",
        name: "Home",
        component: Home
      }
    ]
  },
]

const router = new Router({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    next()
    return
  }

  const { locale } = to.params

  setDocumentLang(locale)
  setDocumentTitle(i18n.t("app.title"))
  setDocumentDescription(i18n.t("app.description"))

  next()
})

export default router
