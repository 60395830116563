export function setDocumentLang(lang) {
    document.documentElement.lang = lang
}

export function setDocumentTitle(newTitle) {
    document.title = newTitle
}

export function setDocumentDescription(newDescription) {
    document.querySelector('meta[name="description"]').setAttribute("content", newDescription);
}