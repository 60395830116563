<template>
  <div id="services">
    <!-- Separator -->
    <div class="custom-shape-divider-top-1617725667">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
    <!-- /Separator -->

    <mdb-container class="p-5">
      <mdb-row class="justify-content-center align-items-center mx-0 py-5">
        <!-- Image -->
        <mdb-col md="6" class="mb-5">
          <img
            class="img-fluid"
            src="@/assets/reformelec-about-us.svg"
            alt="Reformelec about us"
          />
        </mdb-col>
        <!-- /Image -->

        <!-- Content -->
        <mdb-col md="6" class="text-center text-md-left">
          <h2>
            <mdb-icon
              fas
              icon="angle-double-right"
              color="primary"
              class="mr-3"
            />{{ $t("services.title") }}
          </h2>
          <p>
            <mdb-icon far icon="lightbulb" class="text-secondary mr-3" />{{
              $t("services.service1")
            }}
          </p>
          <p>
            <mdb-icon far icon="lightbulb" color="secondary" class="mr-3" />{{
              $t("services.service2")
            }}
          </p>
          <p>
            <mdb-icon far icon="lightbulb" color="secondary" class="mr-3" />{{
              $t("services.service3")
            }}
          </p>
          <p>
            <mdb-icon far icon="lightbulb" color="secondary" class="mr-3" />{{
              $t("services.service4")
            }}
          </p>
          <p>
            <mdb-icon far icon="lightbulb" color="secondary" class="mr-3" />{{
              $t("services.service5")
            }}
          </p>
        </mdb-col>
        <!-- /Content -->
      </mdb-row>
    </mdb-container>
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon } from "mdbvue";

export default {
  name: "Services",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

#services {
  position: relative;
}

.custom-shape-divider-top-1617725667 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1617725667 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 173px;
}

.custom-shape-divider-top-1617725667 .shape-fill {
  fill: #5e003b;
}
</style>