<template>
  <div id="home">
    <header>
      <!--Navbar-->
      <NavBar />
      <!--/Navbar-->

      <mdb-container class="p-5">
        <mdb-row class="justify-content-center align-items-center mx-0 py-5">
          <!-- Presentation -->
          <mdb-col md="6" class="text-center text-md-left">
            <h1
              class="h1-responsive text-primary font-weight-bold animated fadeIn"
            >
              Reformelec
            </h1>
            <h2 class="h2-responsive text-secondary animated fadeIn">
              {{ $t("header.text1") }}<br />
              {{ $t("header.text2") }}
            </h2>
            <p class="font-weight-bold animated fadeIn pt-3">
              <mdb-icon color="primary" fas icon="mobile-alt" class="mr-3" />(+
              376) 338 755
            </p>
            <p class="font-weight-bold animated fadeIn pb-3">
              <mdb-icon
                color="primary"
                far
                icon="envelope-open"
                class="mr-3"
              />reformelec@andorra.ad
            </p>
          </mdb-col>
          <!-- /Presentation -->

          <!-- Image -->
          <mdb-col md="6" class="toHide">
            <mdb-carousel :items="2" :interval="8000">
              <template #1>
                <img
                  class="img-fluid animated fadeIn"
                  src="@/assets/reformelec-technician.svg"
                  alt="Reformelec technician"
                />
              </template>
              <template #2>
                <img
                  class="img-fluid animated fadeIn"
                  src="@/assets/reformelec-electrician.svg"
                  alt="Reformelec technician"
                /> </template
              >-
            </mdb-carousel>
          </mdb-col>
          <!-- /Image -->
        </mdb-row>
      </mdb-container>
    </header>

    <!-- Separator -->
    <div class="custom-shape-divider-bottom-1617725785">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
          class="shape-fill"
        ></path>
      </svg>
      <!-- /Separator -->
    </div>
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon, mdbCarousel } from "mdbvue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Header",
  components: {
    NavBar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbCarousel,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

#home {
  position: relative;
  height: 100vh;
}

.custom-shape-divider-bottom-1617725785 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1617725785 svg {
  position: relative;
  display: block;
  width: calc(226% + 1.3px);
  height: 256px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1617725785 .shape-fill {
  fill: #5e003b;
}
</style>