<template>
  <mdb-btn-fixed
    class="hideOnMobile"
    @click.native.prevent="pageUp"
    color="primary"
    icon="angle-up"
    size="md"
    :bottom="30"
    :right="30"
  />
</template>

<script>
import { mdbBtnFixed } from "mdbvue";

export default {
  data() {
    return {
      active: false,
    };
  },
  name: "BackToTop",
  components: {
    mdbBtnFixed,
  },
  methods: {
    pageUp() {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

.hideOnMobile {
  display: inline;
}

@media only screen and (max-width: 1200px) {
  .hideOnMobile {
    display: inline;
  }
}

@media only screen and (max-width: 991px) {
  .hideOnMobile {
    display: inline;
  }
}

@media only screen and (max-width: 767px) {
  .hideOnMobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hideOnMobile {
    display: none;
  }
}
</style>