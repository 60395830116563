import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import './assets/_custom.scss'
import titleMixin from './mixins/titleMixin'
import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import Notify from 'mdbvue/lib/components/Notify'
Vue.use(Notify)

Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
