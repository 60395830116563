<template>
  <div id="footer">
    <!-- Footer -->
      <mdb-container>
        <mdb-row>
          <mdb-col col="12">
            <div class="mb-4 flex-center">
              <a href="https://www.facebook.com/ReformesAndorra/posts/reformelechttpwwwreformesandorracomreformelec-andorrahtmlinstallacions-electriqu/1981325162099264/"
                ><mdb-icon fab icon="facebook"  class="mx-4" />
              </a>
              <!-- TODO: replace by business link -->
              <a href="https://devloop.dev"
                ><mdb-icon fab icon="google" class="mx-4" />
              </a>
              <a
              href="https://www.reformesandorra.com/reformelec-andorra.html"
              ><mdb-icon fas icon="link" class="mx-4"/>
            </a>
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <div class="text-center text-secondary p-3">
        <mdb-container fluid>
          &copy; 2021 Copyright: <a href="https://devloop.dev"> Devloop </a>
        </mdb-container>
        <a href="https://devloop.dev"> <small>Made by Devloop</small></a>
      </div>
    <!-- Footer -->
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon } from "mdbvue";
export default {
  name: "Footer",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

#footer {
  background-color: $primary-dark !important;
}

a {
  color: $white !important;
  &:hover,
  &:focus,
  &:active {
      color: $secondary-dark !important;
  }
}
</style>